<template>
  <div v-if="!mounted" class="loader h-100">
    <Loader width="140px" height="100%" />
  </div>
</template>

<script>
  import Loader from "@/components/global/Loader.vue";

  export default {
    name: "ShortTokenPage",
    components: {
      Loader
    },
    data() {
      return {
        mounted: false
      };
    },
    async mounted() {
      const shortToken = this.$route.path.replace("/", "");

      if (shortToken) {
        await this.$store.dispatch("shortToken/fetch", shortToken);
        this.mounted = true;
        window.location.href = `${process.env.baseUrl}/?token=${this.$store.state.shortToken.token}`;
      }
    },
    middleware: ["domain-filter"]
  };
</script>

<style scoped>
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

import { render, staticRenderFns } from "./_short_token.vue?vue&type=template&id=2d6ff666&scoped=true"
import script from "./_short_token.vue?vue&type=script&lang=js"
export * from "./_short_token.vue?vue&type=script&lang=js"
import style0 from "./_short_token.vue?vue&type=style&index=0&id=2d6ff666&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d6ff666",
  null
  
)

export default component.exports